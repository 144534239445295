<template>
  <!-- 加工厂单列表 -->
  <div class="page-info-content">
    <!-- 搜索筛选 -->
    <FormSearch
      :getdata="getdata"
      :form-inline="formInline"
      reset
      :form-item-arr="searchFormItemArr"
    />
    <!-- 表格  分页 -->
    <div class="">
      <Table
        :item-data="itemData"
        :list-data="listData"
        :operation-button="operationButton"
        :loading="loading"
      >
        <template #replayDeliveryTime="{ row }">
          <div :class="{'active' : row.replayDeliveryTime && ( row.state === '04' || row.state === '05'|| row.state === '06') && (new Date().getTime() > new Date(row.replayDeliveryTime).getTime() + 3600 * 1000 * 24)}">
            {{ row.replayDeliveryTime }}
            <span v-if="row.replayDeliveryTime && ( row.state === '04' || row.state === '05'|| row.state === '06')">{{ new Date().getTime() > new Date(row.replayDeliveryTime).getTime() + 3600 * 1000 * 24 ? '已超期' : '' }}</span>
          </div>
        </template>
      </Table>
      <!-- 分页 -->
      <Pagination
        :params="formInline"
        :total="total"
        :in-article="listData.length"
        :get-data-list="getdata"
      />
    </div>
  </div>
</template>

<script>
import FormSearch from '@/components/FormSearch.vue'
import Table from '@/components/Table.vue'
import Pagination from '@/components/Pagination2.vue'
import { managerListPageFactoryOrder } from '@/api/processingServices'
import { getSelectAreaTree } from '@/api/login.js'
export default {
  components: { FormSearch, Table, Pagination },
  data() {
    return {
      formInline: {
        pageSize: 10,
        pageNum: 1
      },
      searchFormItemArr: [
        { type: 'input', label: '加工单号', value: 'factoryOrderNo', width: '80px' },
        { type: 'input', label: '委托客户', value: 'entrustClientName', width: '80px' },
        { type: 'input', label: '加工方名称', value: 'processClientName', width: '80px' },
        { type: 'cascader', label: '收货地区', value: 'deliveryAddress', options: [], props: { value: 'name', label: 'name', checkStrictly: true }, width: '80px' },
        { type: 'picker', label: '回复交期', value: 'replayDeliveryTimeArr', startTimer: 'startReplayDeliveryTime', endTimer: 'endReplayDeliveryTime', width: '80px' },
        { type: 'picker', label: '创建时间', value: 'createTimeArr', startTimer: 'startCreateTime', endTimer: 'endCreateTime', width: '80px' },
        { type: 'select', label: '状态', value: 'state', width: '80px', pLabel: 'dictName', pValue: 'dictId', child: this.$store.getters.getDictionaryItem('FACTORY_ORDER_STATUS') }
      ],
      itemData: [
        { label: '加工单号', prop: 'factoryOrderNo', width: 180 },
        { label: '委托客户', prop: 'entrustClientName', width: 180 },
        { label: '加工方名称', prop: 'processClientName', width: 180 },
        { label: '加工费', prop: 'payAmount', width: 180, type: 'money' },
        { label: '收货地区', prop: 'deliveryAddress', width: 180 },
        { label: '回复交期', prop: 'replayDeliveryTime', width: 180 },
        { label: '创建时间', prop: 'createTime', width: 180 },
        {
          label: '状态',
          prop: 'state',
          width: 180,
          child: this.$store.getters.getDictionaryItem('FACTORY_ORDER_STATUS')
        }
      ],
      operationButton: [
        { bType: 'primary', label: '查看详情', handleEvent: this.viewDetails }
      ],
      listData: [],
      total: 0,
      loading: false
    }
  },
  mounted() {
    // 获取地区
    getSelectAreaTree('', (res) => {
      this.searchFormItemArr[3].options = res.data
    })
    this.getdata()
  },
  methods: {
    getdata(type) {
      if (!type) {
        this.formInline.pageNum = 1
      } else if (type === 'reset') {
        this.formInline = {
          pageSize: 10,
          pageNum: 1
        }
      }
      const params = {
        deliveryProvince: this.formInline.deliveryAddress ? this.formInline.deliveryAddress[0] : '',
        deliveryCity: this.formInline.deliveryAddress ? this.formInline.deliveryAddress[1] : '',
        deliveryRegion: this.formInline.deliveryAddress ? this.formInline.deliveryAddress[2] : '',
        ...this.formInline
      }
      managerListPageFactoryOrder(params, res => {
        if (res.data.records) {
          this.listData = [...res.data.records]
          this.total = res.data.total
          return
        }
        this.listData = []
        this.total = 0
      })
    },
    // 查看详情
    viewDetails({ id }) {
      this.$router.push({
        path: '/storageProcess/processOrderDetails',
        query: { id }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.active {
  color: rgba(234, 168, 109, 1);

  > span {
    background-color: rgba(255, 132, 19, 0.06);
    border-radius: 2px;
    padding: 2px;
  }
}
</style>
